import React, { Component } from 'react';
import resume from '../assets/resume.pdf'

class Header extends Component {
  render() {
    if(this.props.data){
      var name = this.props.data.name;
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var city= this.props.data.address.city;
      var networks= this.props.data.social.map(function(network){
         return <li key={network.name}><a target="_blank" href={network.url}><i className={network.className}></i></a></li>
      })
   }

    return (
     <header id="home">
        <nav id="nav-wrap">
           <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
           <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>
           <ul id="nav" className="nav">
              <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
              <li><a className="smoothscroll" href="#about">About</a></li>
              <li><a className="smoothscroll" href="#resume">Job Description</a></li>
              <li><a className="smoothscroll" href="#portfolio">Find Jobs</a></li>
              <li><a className="smoothscroll" href="#contact">Contact Expert</a></li>
              {/* <li ><a   target="_blank" rel="noopener noreferrer" href="{resume}">Resume</a></li> */}
               
           </ul>
         </nav>

         <div className="row banner">
            <div className="banner-text">
               <h1 className="responsive-headline">Looking for work in the USA?
               We're here to help.</h1>
               <h3>Dreaming of a thriving career in the USA? AviationTalent.in Job Search Services (JSS) is your gateway to securing a job in the land of opportunities. Our expert team takes care of everything from crafting your resume to job applications, so you can focus on acing your interviews and advancing your career.</h3>
               <hr />
                
            </div>
         </div>

         <p className="scrolldown">
            <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
         </p>
      </header>
    );
  }
}

export default Header;
