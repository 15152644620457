import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.title}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })
    }

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Job Description</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
               <div ><h4>Job Title : Ground staff,
                Cabin crew,
                Ticketing exicutive ,
                Flight attendant ,
                Airport manager,
                Pilot,
                Pesenger service agent,
                Aircraft Mechanic,
                Avionics Engineer,
                Flight Instructor,
                Air traffic controller,
                Flight Dispatcher,
                Aeronautical engineer,
                Baggage handler,
                Passenger Assistant,
                Aerospace engineer,
                Airfield Operations Specialists,
                Meteorologist,
                Ramp planner,
                Reservation Agent,
                Cargo,
                Fueler,
                Operations Agent,
                Sales Representative,
                Security,
                Agents,
                Aircraft Cleaner</h4>
               </div>
               </div>
            </div>
         </div>
      </div>


      



      <div className="row skill">

       
          <h1><span>Location</span></h1>
       

        <h9>USA</h9>
			</div>

      <br></br><br></br>

      <div className="row skill">

        <h1><span>Salary</span></h1>
       
        <h9>$70.000 To 202.000 per year</h9>
			</div>
      <br></br><br></br>
      <div className="row skill">

        <h1><span>Job Type</span></h1>
       
        <h9>Full-time part-time per-hours work from home</h9>
			</div>
      <br></br><br></br>
      <div className="row skill">

        <h1><span>About American Airlines</span></h1>
       
        <h8>American Airlines is a leading airline operating domestic and international flights, committed to providing exceptional service to passengers worldwide.</h8>
			</div>

     
      <br></br><br></br>
      <div className="row skill">

        <h1><span>Job Description</span></h1>
       
        <h9> American Airlines is currently seeking dedicated individuals to join our American Airlines team at our Usa base. You will get job according to your qualification  member, you will play a crucial role in ensuring the smooth operation of our airline by performing various tasks on the ground, including assisting passengers, handling baggage, and coordinating with other airport personnel.</h9>
			</div>

      
      <br></br><br></br>
      <div className="row skill">

        <h1><span>Key Responsibilities</span></h1>
       
        <ul>
         <li>Greet and assist passengers at check-in counters, boarding gates, and arrival areas.</li>
         <li>Handle passenger inquiries, requests, and concerns in a professional and courteous manner.</li>
         <li>Assist passengers with boarding procedures, including seat assignments and boarding passes.</li>
         <li>Safely and efficiently load and unload baggage onto aircraft.</li>
         <li>Perform security screenings of passengers and baggage in compliance with TSA regulations.</li>
         <li>Coordinate with other ground staff and airline personnel to ensure on-time departures and arrivals.</li>
         <li>Maintain cleanliness and organization of work areas, including gate areas and baggage handling areas.</li>
         <li>Adhere to all safety and security procedures to ensure the safety of passengers and crew.</li>
        </ul>
			</div>

      <br></br><br></br>
      <div className="row skill">

        <h1><span>Requirements</span></h1>
       
        <ul>
         <li>High school diploma or equivalent.</li>
         <li>communication and customer service skills.</li>
         <li>Ability to work in a fast-paced environment and handle multiple tasks simultaneously.</li>
         <li>Strong attention to detail and organizational skills.</li>
         <li>Ability to work flexible hours, including evenings, weekends, and holidays.</li>
         <li>Must be able to lift and carry heavy baggage.</li>
         <li>Previous experience in the airline industry or customer service is preferred but not required.</li>
        </ul>
			</div>

      <br></br><br></br>
      <div className="row skill">

        <h1><span>Benefits</span></h1>
       
        <ul>
         <li>Competitive salary of $70.000 up to $202.000 year.</li>
         <li>Health, dental, and vision insurance.</li>
         <li>Retirement savings plan.</li>
         <li>Paid time off and holiday pay.</li>
         <li>Employee discounts on travel and other perks.</li>
        </ul>
			</div>

      <br></br><br></br>
      <div className="row skill">

        <h1><span>How to Apply</span></h1>
       
        If you are interested in joining the American Airlines Aviation  team as a Ground Staff member in California, please contact us at +18449981909 or email your resume to [insert email address]. Don't miss out on this exciting opportunity to become part of a dynamic and passionate team dedicated to excellence in aviation.

        American Airlines is an equal opportunity employer committed to diversity and inclusion in the workplace. We encourage all qualified individuals to apply.

			</div>

   </section>
    );
  }
}

export default Resume;
