import React, { Component } from 'react';
import resume from '../assets/resume.pdf'

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
    }

    return (
      
      <section id="about">
        
      <div className="row">
          
         <div className="nine columns main-col">
            <h2>About us</h2>

            AviationTalent.in is America's leading job career consultant and one of the world's largest career consulting firms. Established in 1999, our 50+ company-owned and managed offices across the United States, Canada, UK, Australia, UAE, and 1,500+ employees serve over 1,000,000 satisfied customers annually. We work with accredited employment experts in our offices across the globe, including Dubai and Australia. Over 50% of our clients come to us through word-of-mouth referrals. No other company understands the aviation job market like we do. Our service fees are competitive, and we only get paid when we're successful. We offer flexible payment options to suit your needs. Our core competence lies in aviation career consulting, with a proven track record in placing professionals in top airline positions. We handle the highest number of aviation job placements in the industry, drawing on thousands of successful case studies. Our clients trust our brand for the transparency of our processes, which are backed by clear legal agreements, including a straightforward refund policy. Our global career services offer end-to-end solutions, including job search support, allowing you to secure a position in any country and receive ongoing assistance until you are fully settled. We handle your information with the utmost care, using MPLS technology—the highest level of encryption, trusted by financial institutions—to ensure your data is secure and confidential. Clients enjoy a strong rapport with our knowledgeable, experienced consultants who provide high-quality, life-changing career counseling at no cost. Whether you're an individual seeking a career in the airline industry, a corporate partner, or an educational institution, you can trust that you're working with the best in the business.
         </div>
      </div>
      
   </section>
    );
  }
}

export default About;
