import React, { Component } from "react";
import "./Contact.css";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <button onClick={handleClose}>Close</button>
      </section>
    </div>
  );
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
       
      show: false,
    };
  }

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-me", ...this.state }),
    })
      .then(() => {
        this.showModal();
        this.setState({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => alert(error));
    e.preventDefault();
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });
  showModal = () => {
    // if (this.name == "" || this.email == "" || this.message == "") {
    //   return;
    // }
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact">
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Get In Touch.</span>
            </h1>
          </div>
          <div className="ten columns">
            <p className="lead"> Talk to an expert</p>
          </div>
        </div>

        <div className="row">
          

          <aside className="four columns footer-widgets">
            <div className="widget widget_contact">
              <h4>  Phone</h4>
              <p className="address">
                
              <a href="tel:+18449981909">+18449981909</a>
                <br />
                <span>
                  <a
                    href={`mailto:1996meenarahul@gmail.com?subject=The%20subject%20of%20the%20mail`}
                  >
                    {" "}
                    AviationTalent.in{" "}
                  </a>
                </span>
              </p>
            </div>
          </aside>
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <p>
            Thanks for your query.
            <br /> Your message has been sent.
          </p>
        </Modal>
      </section>
    );
  }
}

export default Contact;
